import React from "react";
import { Box, Typography } from "@mui/material";
import ButtonUi from "../button";
import "./layerStripeStyle.scss";

const LayerStripe = () => {
  return (
    <React.Fragment>
      <Box className="stripWrapper" data-aos="zoom-in-up">
        <Box sx={{ padding: "2rem" }}>
          <Typography variant="h5" className="stripeSubTitle">Need a Doctor for Check-up?</Typography>
          <Typography sx={{ fontSize: "40px", fontWeight: "700" }} className="stripeTitle">
            Just make an Appointment & You’re Done!
          </Typography>
          <ButtonUi variant="contained" btnText="Get an Appointment" />
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default LayerStripe;
