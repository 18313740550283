import React from "react";
import "./homeStyle.scss";
import { Box, Typography } from "@mui/material";
import BannerCard from "./bannerCard";
import ClipComp from "./clipComp";
import ServicesComp from "./servicesComp";
import FeedbackComp from "./feedback";
import LayerStripe from "../../components/layerStripe";
import QuotesCarousel from "../../components/quotesCarousel";
import Specialities from "./specialitiesComp";
import CounterLayer from "../../components/counter";
import ButtonUi from "../../components/button";
import Header from "../../components/header";
import Footer from "../../components/footer";
// import TypeAnimationComp from "../../components/typeAnimation";
import Sticker1 from "../../assets/images/bannerSticker1.png";
import Sticker2 from "../../assets/images/bannerSticker3.png";
import { useNavigate } from "react-router-dom";
import Info from "./info";
import HowItWork from "./howItWork";
// import SocialIconStripe from "../../components/socialIconStripe";

const Home = () => {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <Header />
      <Box className="homeBanner">
        <Box className="bannerStickerAnim">
          <img src={Sticker1} alt="Banner Sticker" width="50px" />
        </Box>
        <Box className="bannerStickerAnim2">
          <img src={Sticker2} alt="Banner Sticker" width="50px" />
        </Box>
        <Box className="innerWrapper">
          <Box sx={{ paddingLeft: "2rem" }}>
            <Typography variant="p" className="bannerSubTitle">
              24x7 Medical Key providing all medical & health services in Patna,
              Bihar.
            </Typography>
            {/* <TypeAnimationComp /> */}
            <Typography variant="h2" className="bannerTitle">
              Medical & Health<br/>Service Provider!
            </Typography>
            <ButtonUi
              btnText="Read More"
              onClick={() => navigate('/about')}
            />{" "}
            <ButtonUi
              btnText="24/7 Customer Support"
              onClick={() => navigate("/contact")}
            />
          </Box>
        </Box>
      </Box>
      <BannerCard />
      <ClipComp />
      <CounterLayer />
      <Info />
      <ServicesComp />
      <QuotesCarousel />
      <Specialities />
      <HowItWork />
      {/* <SocialIconStripe /> */}
      {/* <FeedbackComp /> */}
      <LayerStripe />
      <Footer />
    </React.Fragment>
  );
};

export default Home;
