import React, { useState, useEffect } from "react";
import { Box, Grid, Typography, Container, List } from "@mui/material";
import CopyrightIcon from "@mui/icons-material/Copyright";
import LOGO from "../../assets/images/logo.png";
import FooterImg1 from "../../assets/images/footerImg1.jpg";
import FooterImg2 from "../../assets/images/footerImg2.jpg";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import FooterLinks from "./footerLinks";
import { FloatingIcon } from "../floatingIcons";
import "./footerStyle.scss";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  const [showButton, setShowButton] = useState(false);

  const handleScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    setShowButton(scrollTop > 100);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      backToTopHandler();
    };
  }, []);

  const backToTopHandler = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <React.Fragment>
      <Box className="footerStripe">
        <Typography variant="p" data-aos="zoom-in-up">
          Medical key provide professional healthcare services such as elderly
          care, post-surgical care, chronic illness or patient care at home in
          Patna. We are also setting up chain of pharmacies across India along
          with Polyclinics, Collection centres and Telemedicine Clinics.
        </Typography>
      </Box>
      <Box className="footWrapper">
        <Container>
          <Grid container>
            <Grid
              item
              md={4}
              xs={12}
              className="companyInfoBlock"
              data-aos="zoom-in-up"
            >
              <Box>
                <Box>
                  <img src={LOGO} alt="Brand Logo" className="logo" />
                </Box>
                <Typography variant="p" className="companyInfo">
                  Medical Key is a medical service provider consultants
                  platform. We are providing all Medical and Health Services in
                  Patna Bihar. Medical Key Providing affordable, Quality health
                  care to Patients.
                </Typography>
                <Box>
                  <List>
                    <FacebookIcon className="footerIconStyle" />
                    <InstagramIcon className="footerIconStyle" />
                    <LinkedInIcon className="footerIconStyle" />
                    <TwitterIcon className="footerIconStyle" />
                    <MailOutlineIcon className="footerIconStyle" />
                  </List>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              md={4}
              xs={12}
              className="footerLinksWrapper"
              data-aos="zoom-in-up"
            >
              <FooterLinks />
            </Grid>
            <Grid
              item
              md={4}
              xs={12}
              sx={{ padding: "2rem" }}
              data-aos="zoom-in-up"
            >
              <Typography variant="h4" className="footerLinkHead">
                Gallery
              </Typography>
              <Box sx={{ textAlign: "" }}>
                <Grid container>
                  <Grid item md={6} xs={6}>
                    <img
                      src={FooterImg1}
                      alt="Footer Pic"
                      style={{ width: "100%" }}
                      width="200px"
                    />
                  </Grid>
                  <Grid item md={6} xs={6}>
                    <img
                      src={FooterImg2}
                      alt="Footer Pic"
                      style={{ width: "100%" }}
                      width="200px"
                    />
                  </Grid>
                  <Grid item md={6} xs={6}>
                    <img
                      src={FooterImg2}
                      alt="Footer Pic"
                      style={{ width: "100%" }}
                      width="200px"
                    />
                  </Grid>
                  <Grid item md={6} xs={6}>
                    <img
                      src={FooterImg1}
                      alt="Footer Pic"
                      style={{ width: "100%" }}
                      width="200px"
                    />
                  </Grid>
                </Grid>
                <Typography
                  sx={{ textAlign: "end" }}
                  onClick={()=>navigate('/gallery')}
                >{`View More >>`}</Typography>
              </Box>
            </Grid>
          </Grid>
          <hr />
        </Container>
        <Box className="footerBottom">
          <Typography>
            Copyright. All Rights Reserved <CopyrightIcon /> 2023.
          </Typography>
          <Typography>
            Develop by <span>@Alkama Azim</span>
          </Typography>
        </Box>
      </Box>
      <FloatingIcon />
      {showButton && (
        <Box className="backToTopWrapper" onClick={backToTopHandler}>
          <i className="fa-solid fa-arrow-up"></i>
        </Box>
      )}
    </React.Fragment>
  );
};

export default Footer;
