import React from "react";
import {
  Box,
  List,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Grid,
  Typography,
} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import "./footerStyle.scss";
import { useNavigate } from "react-router-dom";

const FooterLinks = () => {
  const navigate = useNavigate();
  const footerLinks1 = {
    Home: "/",
    About: "/about",
    Contact: "/contact",
    "Privacy Policy": "link",
  };
  const footerLinks2 = {
    Specialities: "link",
    Services: "link",
    Gallery: "link",
    Enquiry: "link",
  };

  return (
    <Box sx={{ textAlign: "center" }}>
      <Grid container>
        <Grid item md={6} xs={12}>
          <Typography variant="h4" className="footerLinkHead">
            Quick Links
          </Typography>
          <List>
            {Object.entries(footerLinks1).map(([key, val], index) => (
              <ListItemButton onClick={() => navigate(`${val}`)} key={index}>
                <ListItemIcon>
                  <ChevronRightIcon />
                </ListItemIcon>
                <ListItemText primary={key} className="footerLink" />
              </ListItemButton>
            ))}
          </List>
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography variant="h4" className="footerLinkHead">
            Quick Links
          </Typography>
          <List>
            {Object.entries(footerLinks2).map(([key, val], index) => (
              <ListItemButton onClick={() => alert(`${val}`)} key={index}>
                <ListItemIcon>
                  <ChevronRightIcon />
                </ListItemIcon>
                <ListItemText primary={key} className="footerLink" />
              </ListItemButton>
            ))}
          </List>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FooterLinks;
