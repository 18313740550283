import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Paper, Box, Typography } from "@mui/material";
import Img1 from "../../assets/images/ser1.png";
import Img2 from "../../assets/images/ser2.png";
import Img3 from "../../assets/images/ser3.png";
import Img4 from "../../assets/images/ser4.png";
import { Container } from "react-bootstrap";
import "./style.scss";

const iconsListObj = [
  {
    title: "Cardiologist",
    description: `specializes in the study or treatment of heart diseases and heart abnormalities. "if you have chest pains I suggest you see a cardiologist".`,
    icon: Img1,
  },
  {
    title: "Orthopaedics",
    description:
      "orthopaedic surgeon is an expert with extensive training in the proper diagnosis and both non-surgical and surgical treatment of injuries and diseases of the musculoskeletal system.",
    icon: Img2,
  },
  {
    title: "Gastrologist",
    description:
      "specialists that treat digestive, gastrointestinal,stomach research, its structure, functions, illnesses, as well as liver disorders and diseases in both men and women.",
    icon: Img4,
  },
  {
    title: "Physician",
    description:
      "Treatment of disease, injury, and other physical and mental impairments. Usually focus on the non-surgical treatment of patient conditions.",
    icon: Img3,
  },
];

const Carousel = () => {
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "10px",
    slidesToShow: 3,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Container>
      <Slider {...settings}>
        <Box className="serviceGridWrapper">
          <Paper className="iconCardWrapper">
            <Box className="cardIcon">
              <img
                src={Img1}
                alt="Cardiologist"
                width="100px"
                className="homeServiceIcon"
              />
            </Box>
            <Typography variant="h5" className="cardTitle">
              Cardiologist
            </Typography>
            <Typography variant="p" sx={{ textAlign: "justify" }}>
              specializes in the study or treatment of heart diseases and heart
              abnormalities. "if you have chest pains I suggest you see a
              cardiologist".
            </Typography>
          </Paper>
        </Box>
        <Box className="serviceGridWrapper">
          <Paper className="iconCardWrapper">
            <Box className="cardIcon">
              <img
                src={Img2}
                alt="Cardiologist"
                width="100px"
                className="homeServiceIcon"
              />
            </Box>
            <Typography variant="h5" className="cardTitle">
              Orthopaedics
            </Typography>
            <Typography variant="p" sx={{ textAlign: "justify" }}>
              orthopaedic surgeon is an expert with extensive training in the
              proper diagnosis and both non-surgical and surgical treatment of
              injuries and diseases of the musculoskeletal system.
            </Typography>
          </Paper>
        </Box>
        <Box className="serviceGridWrapper">
          <Paper className="iconCardWrapper">
            <Box className="cardIcon">
              <img
                src={Img3}
                alt="Cardiologist"
                width="100px"
                className="homeServiceIcon"
              />
            </Box>
            <Typography variant="h5" className="cardTitle">
              Gastrologist
            </Typography>
            <Typography variant="p" sx={{ textAlign: "justify" }}>
              specialists that treat digestive, gastrointestinal,stomach
              research, its structure, functions, illnesses, as well as liver
              disorders and diseases in both men and women.
            </Typography>
          </Paper>
        </Box>
        <Box className="serviceGridWrapper">
          <Paper className="iconCardWrapper">
            <Box className="cardIcon">
              <img
                src={Img4}
                alt="Cardiologist"
                width="100px"
                className="homeServiceIcon"
              />
            </Box>
            <Typography variant="h5" className="cardTitle">
              Physician
            </Typography>
            <Typography variant="p" sx={{ textAlign: "justify" }}>
              Treatment of disease, injury, and other physical and mental
              impairments. Usually focus on the non-surgical treatment of
              patient conditions.
            </Typography>
          </Paper>
        </Box>
        <Box className="serviceGridWrapper">
          <Paper className="iconCardWrapper">
            <Box className="cardIcon">
              <img
                src={Img1}
                alt="Cardiologist"
                width="100px"
                className="homeServiceIcon"
              />
            </Box>
            <Typography variant="h5" className="cardTitle">
              Surgeon
            </Typography>
            <Typography variant="p" sx={{ textAlign: "justify" }}>
              Surgeon operate on patient to treat injuries, such as broken bone;
              diseases, such as cancerous tumors; and deformities, such as cleft
              palate.
            </Typography>
          </Paper>
        </Box>
        <Box className="serviceGridWrapper">
          <Paper className="iconCardWrapper">
            <Box className="cardIcon">
              <img
                src={Img1}
                alt="Cardiologist"
                width="100px"
                className="homeServiceIcon"
              />
            </Box>
            <Typography variant="h5" className="cardTitle">
              Neurologist
            </Typography>
            <Typography variant="p" sx={{ textAlign: "justify" }}>
              Neurologist or Neurosurgeon Diagnosis and treatment of diseases of
              the brain & nervous system.
            </Typography>
          </Paper>
        </Box>
        <Box className="serviceGridWrapper">
          <Paper className="iconCardWrapper">
            <Box className="cardIcon">
              <img
                src={Img1}
                alt="Cardiologist"
                width="100px"
                className="homeServiceIcon"
              />
            </Box>
            <Typography variant="h5" className="cardTitle">
              Urologist
            </Typography>
            <Typography variant="p" sx={{ textAlign: "justify" }}>
              urological surgeon treat problem of the female urinary system and
              the male genitourinary track. Diagnose and treat disorder of the
              kidney, ureter, bladder, prostate and male reproductive.
            </Typography>
          </Paper>
        </Box>
        <Box className="serviceGridWrapper">
          <Paper className="iconCardWrapper">
            <Box className="cardIcon">
              <img
                src={Img1}
                alt="Cardiologist"
                width="100px"
                className="homeServiceIcon"
              />
            </Box>
            <Typography variant="h5" className="cardTitle">
              Palmonologist
            </Typography>
            <Typography variant="p" sx={{ textAlign: "justify" }}>
              Palmonologist or chest physician diagnosis and treats disease of
              the respiratory system. Includes nose, throat, trachea, Airways,
              lungs, muscles, blood vessels.
            </Typography>
          </Paper>
        </Box>
      </Slider>
    </Container>
  );
};

export default Carousel;
