import React from "react";
import { Grid, Container, Box, Typography } from "@mui/material";
import ImgIcon1 from "../../assets/images/bannerCard1.gif";
import ImgIcon2 from "../../assets/images/bannerCard2.gif";
import ImgIcon3 from "../../assets/images/bannerCard3.gif";
import ImgIcon4 from "../../assets/images/bannerCard4.gif";
import "./homeStyle.scss";
import { useNavigate } from "react-router-dom";

const BannerCard = () => {
  const navigate = useNavigate();
  const bannerCardObj = [
    {
      title: "Home Healthcare Service",
      subTitle: "View More",
      icon: ImgIcon1,
      link: "/homehealthcare",
    },
    {
      title: "Healthcare Consultancy Service",
      subTitle: "View More",
      icon: ImgIcon2,
      link: "/services",
    },
    {
      title: "Complete Hospitality Solution",
      subTitle: "View More",
      icon: ImgIcon3,
      link: "/services",
    },
    {
      title: "Placement Solution",
      subTitle: "View More",
      icon: ImgIcon4,
      link: "/services",
    },
  ];

  return (
    <React.Fragment>
      <Container className="bannerContainerWrapper" data-aos="zoom-in-up">
        <Grid container>
          {bannerCardObj.map((item, index) => (
            <Grid
              item
              md={3}
              sm={6}
              xs={12}
              className="bannerCardWrapper"
              key={index}
            >
              <Box
                sx={{
                  textAlign: "center",
                  padding: "2rem 0",
                  cursor: "pointer",
                }}
                onClick={() => navigate(item.link)}
              >
                <Box className="bannerIcon">
                  <img src={item.icon} alt={item.title} width="100" />
                </Box>
                <Typography variant="h4" className="bannerCardTitle">
                  {item.title}
                </Typography>
                <Typography variant="p">
                  {item.subTitle}{" "}
                  <i className="fa-solid fa-arrows-turn-right"></i>
                </Typography>
              </Box>
            </Grid>
          ))}
          <Box className="overlay"></Box>
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default BannerCard;
