import React, { useState } from "react";
import {
  Container,
  Grid,
  Box,
  Typography,
  Card,
  CardContent,
} from "@mui/material";
import ButtonUi from "../../components/button";
import VideoClip from "../../assets/videos/clip1.mp4";
import Sticker from "../../assets/images/doctor2.png";
import "./homeStyle.scss";
import { useNavigate } from "react-router-dom";

const Img =
  "https://cdn.pixabay.com/photo/2017/10/04/09/56/laboratory-2815641_960_720.jpg";

const ClipComp = () => {
  const navigate = useNavigate();
  const [clipStatus, setClipStatus] = useState(false);

  const handlePlayStatus = () => {
    setTimeout(()=>{
      setClipStatus(true);
    }, 2000)
  };

  return (
    <React.Fragment>
      <Box className="clipContainerWrapper">
        <Container>
          <Grid container className="clipGridWrapper">
            <Grid item md={6} xs={12} className="clipContentWrapper">
              <Box className="clipBox" data-aos="zoom-in-up">
                <Box className="circleTexture"></Box>
                <Typography variant="h4" className="clipTitle">
                  Get Cared For By The Best!
                </Typography>
                <Typography variant="p" className="clipText">
                  A Complete Healthcare Solution. <strong>Medical Key</strong>{" "}
                  is a plateform that provide all medical & Health services.{" "}
                  <strong>Providing HOME HEALTHCARE SERVICE in Patna.</strong>{" "}
                  One stop solution for all your needs Sach as:-Best Doctor
                  consultation, Best Hospital Suggestion, All kinds of Home
                  service, Complete Hospitality Solution, Career Counseling,
                  Placement Solution, Medical Equipment MRI, CT-SCAN, Blood
                  Test, Ultrasound etc Ambulance, Medicine & Surgical items etc
                </Typography>
                <Typography variant="p" className="clipText">
                  <strong>Medical key is registered with ISO and MSME.</strong>{" "}
                  Our management systems have been designed to comply with all
                  applicable regulations & Medical key is always there for you!
                  Our Response Center is available 24 hours a day, 7 days a week
                  and staffed with meticulously trained, caring Personal
                  Response Associates. We appreciate your patience, and we
                  assure you that we will take your call just as quickly as
                  possible.
                </Typography>
                <Box className="buttonStyle">
                  <ButtonUi
                    btnText="Read More"
                    onClick={() => navigate("/about")}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item md={6} xs={12} className="clipVideoWrapper">
              <Card className="clipVideoCard" data-aos="zoom-in-up">
                <CardContent>
                  <Box className="videoClipWrapper">
                    {!clipStatus && <img src={Img} alt="hello" width="100%" />}
                    {clipStatus !== false && (
                      <video
                        src={VideoClip}
                        controls
                        autoPlay="true"
                        width="100%"
                        height="100%"
                      />
                    )}
                    {!clipStatus && (
                      <Box className="videoClipBtn">
                        <Box
                          className="play-btn"
                          onClick={handlePlayStatus}
                        ></Box>
                      </Box>
                    )}
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Box className="textureDesign"></Box>
            <Box className="clipSticker">
              <img src={Sticker} alt="Sticker" />
            </Box>
          </Grid>
        </Container>
        <Box className="beforeTextureImg">
          <img src="https://template.viserlab.com/viserfix/demos/assets/images/home-02/about/about-left-bg.png" />
        </Box>
        <Box className="beforeTextureImg2">
          <img src="https://template.viserlab.com/viserfix/demos/assets/images/home-02/about/about-right-bg.png" />
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default ClipComp;
