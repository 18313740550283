import React, { useState } from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  Card,
  CardContent,
} from "@mui/material";
import ButtonUi from "../../components/button";
import ServicesIcons from "./servicesIcons";
import VideoClip from "../../assets/videos/clip2.mp4";
import Sticker from "../../assets/images/doctor.png";
import "./homeStyle.scss";
import { useNavigate } from "react-router-dom";
import Carousel from "../../components/carousel";

const Img =
  "https://cdn.pixabay.com/photo/2017/10/04/09/56/laboratory-2815641_960_720.jpg";

const ServicesComp = () => {
  const navigate = useNavigate();
  const [clipStatus, setClipStatus] = useState(false);

  const handlePlayStatus = () => {
    setTimeout(() => {
      setClipStatus(true);
    }, 2000);
  };

  return (
    <React.Fragment>
      <Box className="servicesGridWrapper">
        <Container>
          <Box sx={{ textAlign: "end", marginBottom:"1rem" }}>
            <ButtonUi
              btnText="View Services"
              onClick={() => navigate("/services")}
            />
          </Box>
          {/* <ServicesIcons /> */}
          <Carousel />
          <Grid container>
            <Grid item md={6} xs={12} className="servicesClipWrapper">
              <Card className="clipVideoCard" data-aos="zoom-in-up">
                <CardContent>
                  <Box className="videoClipWrapper">
                    {!clipStatus && <img src={Img} alt="hello" width="100%" />}
                    {clipStatus !== false && (
                      <video
                        src={VideoClip}
                        controls
                        autoPlay="true"
                        width="100%"
                        height="100%"
                      />
                    )}
                    {!clipStatus && (
                      <Box className="videoClipBtn">
                        <Box
                          className="play-btn"
                          onClick={handlePlayStatus}
                        ></Box>
                      </Box>
                    )}
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={6} xs={12} className="servicesContentWrapper">
              <Box className="servicesBox" data-aos="zoom-in-up">
                <Box className="circleTexture"></Box>
                <Typography variant="h4" className="servicesTitle">
                  Departments
                </Typography>
                <Typography variant="p" className="servicesText">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries
                </Typography>
                <Box className="buttonStyle">
                  <ButtonUi
                    btnText="View Departments"
                    onClick={() => navigate("/departmentList")}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
        <Box className="serviceSticker">
          <img src={Sticker} alt="Sticker Image" />
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default ServicesComp;
