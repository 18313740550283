import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  Typography,
} from "@mui/material";
import React from "react";
import "./homeStyle.scss";
import Img from "../../assets/images/howItWork.jpg";
import Count1 from "../../assets/images/1.png";
import Count2 from "../../assets/images/2.png";
import Count3 from "../../assets/images/3.png";
import Count4 from "../../assets/images/4.png";
import Icon1 from "../../assets/images/work1.png";
import Icon2 from "../../assets/images/work2.png";
import Icon3 from "../../assets/images/work3.png";
import Icon4 from "../../assets/images/work4.png";

const HowItWork = () => {
  return (
    <React.Fragment>
      <Box className="howItWorkSection">
      <Box className="beforeTextureImg2">
        <img src="https://template.viserlab.com/viserfix/demos/assets/images/home-02/about/about-right-bg.png" />
      </Box>
        <Grid container>
          <Grid item md={5} xs={12}>
            <Box className="rightContentWrapper">
              <List
                sx={{
                  width: "100%",
                  maxWidth: 400,
                  bgcolor: "background.paper",
                }}
              >
                {data.map((item, index) => (
                  <ListItem key={index}>
                    <ListItemAvatar>
                      <img src={item.image} alt="Count Icon" width="60px" />
                    </ListItemAvatar>
                    <Box>
                      
                      <Typography variant="h4" className="title">
                      <img src={item.icon} alt="icon" width="50px" />{" "}
                        {item.title}
                      </Typography>
                      <Typography variant="span">{item.subTitle}</Typography>
                    </Box>
                  </ListItem>
                ))}
              </List>
            </Box>
          </Grid>
          <Grid item md={7} xs={12}>
            <Box className="leftImageWrapper">
              <img src={Img} alt="How It Work Banner" width="100%" />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
};

export default HowItWork;

const data = [
  {
    image: Count1,
    icon: Icon1,
    title: "Request",
    subTitle: "Book an online appointment. Request a call back on website, Simply dial +91-9341819717",
  },
  {
    image: Count2,
    icon: Icon2,
    title: "Response",
    subTitle: "Our executive gets in touch with you and schedules an appointment.",
  },
  {
    image: Count3,
    icon: Icon3,
    title: "Services",
    subTitle:
      "Our qualified expert visits your home initiates the service.",
  },
  {
    image: Count4,
    icon: Icon4,
    title: "Monitoring",
    subTitle: "Mesures improvement over time and recommends next steps if any.",
  },
];
