import React, { useState } from "react";
import { Drawer } from "@mui/material";
import { useNavigate } from "react-router-dom";
import LOGO from "../../assets/images/logo.png";
import "./headerStyle.scss";
import ButtonUi from "../button";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemButton,
  ListItemText,
  Button,
  Box,
  Typography,
} from "@mui/material";

const linksObj1 = [
  {
    title: "Home",
    link: "/",
  },
  {
    title: "About",
    link: "/about",
  },
  {
    title: "Services",
    link: "/services",
  },
  {
    title: "Contact",
    link: "/contact",
  },
];

const linksObj2 = [
  {
    title: "Gallery",
    link: "/gallery",
  },
  {
    title: "Specialities",
    link: "/specialitiesList",
  },
  {
    title: "Department",
    link: "/departmentList",
  },
]

const DrawerComp = () => {
  const navigate = useNavigate();
  const [state, setState] = useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      // sx={{ width: 250, backgroundImage:"url('../../assets/images/logo.png')", height:"100vh" }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
      className="drawer-wrapper"
    >
      <Box
        sx={{
          margin: "1rem 0.5rem",
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
        }}
      >
        <img
          src={LOGO}
          alt="LOGO"
          style={{ width: "60px", marginRight: "0.2rem" }}
        />
        <Box className="logoTextWrapper">
          <Typography
            sx={{
              fontSize: "20px",
              letterSpacing: "1px",
              fontFamily: "Anton, sans-serif",
              color: "#2c3374",
            }}
          >
            MEDICAL KEY
          </Typography>
        </Box>
      </Box>
      <hr />
      <List>
        {linksObj1.map((item, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton
              onClick={() => navigate(item.link)}
              sx={{ borderBottom: "1px solid #f1f1f1" }}
            >
              <ListItemIcon>
                <i
                  className="fa-solid fa-arrow-right"
                  style={{ color: "#ABC2E8" }}
                ></i>
              </ListItemIcon>
              <ListItemText primary={item.title} sx={{color:"#2c3374"}} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <List>
        {linksObj2.map((item, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton
              onClick={() => navigate(item.link)}
              sx={{ borderBottom: "1px solid #f1f1f1" }}
            >
              <ListItemIcon>
                <i
                  className="fa-solid fa-arrow-right"
                  style={{ color: "#ABC2E8" }}
                ></i>
              </ListItemIcon>
              <ListItemText primary={item.title} sx={{color:"#2c3374"}} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Box sx={{ textAlign: "center" }}>
        <ButtonUi btnText="Book Appointment" />
      </Box>
      <Box
        sx={{
          position: "absolute",
          bottom: "10px",
          padding: "0 10px",
          fontSize: "12px",
        }}
      >
        <Typography variant="p">All Rights Reserved 2023.</Typography>
      </Box>
    </Box>
  );

  return (
    <Box>
      {["left"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button
            onClick={toggleDrawer(anchor, true)}
            sx={{ display: { xs: "block", sm: "none" }, color: "#2c3374" }}
          >
            {anchor}
          </Button>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </Box>
  );
};

export default DrawerComp;
