import React from "react";
// import { Button } from "@mui/material";
import "./buttonStyle.scss";

const ButtonUi = ({ type, btnText, onClick }) => {
  return (
    <React.Fragment>
      <button type={type} className="button" onClick={onClick}>
        <span className="button-content">{btnText} </span>
      </button>
    </React.Fragment>
  );
};

export default ButtonUi;
