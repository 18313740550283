import React, { useState } from "react";
import { Button, Drawer } from "@mui/material";
import { Box, Typography } from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import ButtonUi from "../button";
import * as Yup from "yup";
import "./style.scss";
import Logo from "../../assets/images/logo.png";

// Formik Yup validation === >>>
const Schema = Yup.object({
  name: Yup.string().min(3).max(25).trim().required(),
  email: Yup.string().email().required(),
  phone: Yup.string().min(10).trim().required(),
});

const initialValues = {
  name: "",
  email: "",
  phone: "",
};

const AppointmentForm = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <Box>
      <ButtonUi onClick={toggleDrawer} btnText="Book Appointment" />
      <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer}>
        <Box className="drawerWrapper">
          <Box>
            <img src={Logo} alt="Logo" width="70px" />
            <Box className="formSologan">
              <Typography variant="p">
                Better Health, Better Tomorrow.
              </Typography>
            </Box>
            <Formik
              initialValues={initialValues}
              validationSchema={Schema}
              onSubmit={(values, action) => {
                alert(values);
              }}
            >
              {({ isSubmitting }) => (
                <Form>
                  <Box className="mb-3">
                    <Field
                      name="name"
                      className="form-control"
                      placeholder="Your Full Name"
                    />
                    <ErrorMessage name="name" component="div" />
                  </Box>
                  <Box className="mb-3">
                    <Field
                      type="email"
                      name="email"
                      className="form-control"
                      placeholder="Your Email"
                    />
                    <ErrorMessage name="email" component="div" />
                  </Box>
                  <Box className="mb-3">
                    <Field
                      name="phone"
                      className="form-control"
                      placeholder="Contact No"
                    />
                    <ErrorMessage name="phone" component="div" />
                  </Box>
                  <ButtonUi btnText="Proceed" type="submit" />
                </Form>
              )}
            </Formik>
          </Box>
        </Box>
      </Drawer>
    </Box>
  );
};

export default AppointmentForm;
