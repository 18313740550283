import React from "react";
import { Box, Typography } from "@mui/material";
import "./style.scss";

const LazyLoader = ({loadingText = ""}) => {
  return (
    <Box className="lazy-loader-wrapper">
      <Box className="dot-spinner">
        <Box className="dot-spinner__dot"></Box>
        <Box className="dot-spinner__dot"></Box>
        <Box className="dot-spinner__dot"></Box>
        <Box className="dot-spinner__dot"></Box>
        <Box className="dot-spinner__dot"></Box>
        <Box className="dot-spinner__dot"></Box>
        <Box className="dot-spinner__dot"></Box>
        <Box className="dot-spinner__dot"></Box>
      </Box>{" "}
      <Typography variant="p" className="">{loadingText}</Typography>
    </Box>
  );
};

export default LazyLoader;
