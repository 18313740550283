import React from "react";
import {
  Container,
  Grid,
  Box,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
} from "@mui/material";
import ButtonUi from "../../components/button";
import Sticker from "../../assets/images/ambulance.png";
import "./homeStyle.scss";
import { useNavigate } from "react-router-dom";
import Img from "../../assets/images/bannerSpec.jpeg";
import IconImg1 from "../../assets/images/spec1.png";
import IconImg2 from "../../assets/images/spec2.png";
import IconImg3 from "../../assets/images/spec3.png";
import IconImg4 from "../../assets/images/spec4.png";

// const Img =
//   "https://cdn.pixabay.com/photo/2017/10/04/09/56/laboratory-2815641_960_720.jpg";

const Specialities = () => {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <Box className="specialitiesContainerWrapper">
        <Box className="beforeTextureImgSpec">
          <img src="https://template.viserlab.com/viserfix/demos/assets/images/home-01/pricing/pricing-bg.png" />
        </Box>
        <Container>
          <Grid container className="specialitiesGridWrapper">
            <Grid item md={6} xs={12} className="specialitiesClipWrapper">
              {/* <Card className="specialitiesClipCard" data-aos="zoom-in-up">
                <CardContent> */}
              <Box className="specialitiesImage">
                <img src={Img} alt="Specialities" />
              </Box>
              {/* </CardContent>
              </Card> */}
            </Grid>
            <Grid item md={6} xs={12} className="specialitiesContentWrapper">
              <Box className="specialitiesBox" data-aos="zoom-in-up">
                <Box className="circleTexture"></Box>
                <Typography variant="h4" className="specialitiesTitle">
                  Specialities
                </Typography>
                <Typography variant="p" className="specialitiesText">
                  One stop solution for all your health needs such as:- Best
                  Doctors Consultation, Best Hospitals Suggestion , AMBULANCE
                  Service & Emergency Trauma care, Pathology(Blood & Laboratory
                  related all test), Radiology(MRI, CT-SCAN, Digital X-RAY,
                  Ultrasound, ECHO, ECG, Angiography, etc), Physiotherapy,
                  Dialysis, Endoscopy, Medicine, Medical Equipments & 200+
                  medical services.
                </Typography>
                <Box className="specialitiesIconWrapper">
                  <List
                    sx={{
                      width: "100%",
                      // maxWidth: 400,
                      bgcolor: "background.paper",
                    }}
                  >
                    {specData.map((item, index) => (
                      <ListItem key={index}>
                        <ListItemAvatar className="specIconBox">
                          <img src={item.image} alt="check Icon" width="60px" />
                        </ListItemAvatar>
                        <Box>
                          <Typography variant="h4" className="title">
                            {item.title}
                          </Typography>
                          <Typography variant="span">{item.subTitle}</Typography>
                        </Box>
                        {/* <ListItemText
                      primary={item.title}
                      secondary={item.subTitle}
                    /> */}
                      </ListItem>
                    ))}
                  </List>
                </Box>
                <Box className="buttonStyle">
                  <ButtonUi
                    btnText="View Specialities"
                    onClick={() => navigate("/specialitiesList")}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Box className="textureCircleDesign"></Box>
          <Box className="specialitiesSticker">
            <img src={Sticker} alt="Sticker" />
          </Box>
        </Container>
      </Box>
    </React.Fragment>
  );
};

export default Specialities;

const specData = [
  {
    title: "DEPARTMENTS",
    image: IconImg1,
    subTitle: "The Backbone of our Company"
  },
  {
    title: "MEDICAL SERVICES",
    image: IconImg2,
    subTitle: "A list of all available"
  },
  {
    title: "FIND A DOCTOR",
    image: IconImg3,
    subTitle: "All our staff by department"
  },
  {
    title: "REQUEST AN APPOINTMENT",
    image: IconImg4,
    subTitle: "Call us or fill in a form"
  },
];
