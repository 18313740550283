import React from "react";
import {
  AppBar,
  styled,
  Toolbar,
  Typography,
  Box,
  List,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import LOGO from "../../assets/images/logo.jpg";
import TopHeadStrip from "./topHeadStrip";
import DrawerComp from "./drawerComp";
import "./headerStyle.scss";
import AppointmentForm from "../appointmentForm"

const StyledToolbar = styled(Toolbar)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: "#ffffff",
  borderBottom: "1px solid #f2f2f2",
});

const MainLink = styled(Box)(({ theme }) => ({}));

const LinkObj = [
  { text: "Home", destination: "/" },
  { text: "About", destination: "/about" },
  { text: "Services", destination: "/services" },
  { text: "Contact", destination: "/contact" },
];

const linkStyle = {
  // backgroundColor: "red",
};

const Header = () => {
  const navigate = useNavigate();
  // const [activeLink, setActiveLink] = useState("");

  const hyperLinkHandler = (item, value) => {
    if (item === "Home") {
      navigate(value);
    } else if (item === "About") {
      navigate(value);
    } else if (item === "Services") {
      navigate(value);
    } else {
      navigate(value);
    }
  };

  return (
    <React.Fragment>
      <TopHeadStrip />
      <AppBar position="sticky" sx={{ boxShadow: { md: "none" } }}>
        <StyledToolbar>
          <Box className="logoWrapper">
            <img src={LOGO} alt="Brand Logo" className="logo" />
            <Box className="logoTextWrapper">
              <Typography className="logoText">MEDICAL KEY</Typography>
              {/* <Typography sx={{color:"red"}}>A key of Your Health</Typography> */}
            </Box>
          </Box>
          <Box sx={{ display: { md: "none", xs: "block" } }}>
            <i className="fa-solid fa-bars"></i>
          </Box>
          {/* <ReorderIcon
            onClick={() => toggleDrawer(anchor, true)}
            sx={{ display: { xs: "block", sm: "none" }, color: "#2c3374" }}
          /> */}
          <DrawerComp />
          <MainLink sx={{ display: { xs: "none", sm: "block" } }}>
            <List
              className="headerLinkList"
              component="nav"
              aria-labelledby="nested-list-subheader"
            >
              {LinkObj.map((item, index) => (
                <ListItemButton
                  key={index}
                  onClick={() => hyperLinkHandler(item.text, item.destination)}
                  style={linkStyle}
                >
                  <ListItemText primary={item.text} />
                </ListItemButton>
              ))}
              <AppointmentForm />
            </List>
          </MainLink>
        </StyledToolbar>{" "}
      </AppBar>
    </React.Fragment>
  );
};

export default Header;
