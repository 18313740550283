import React from "react";
import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import "./headerStyle.scss";

const mail = <i className="fa-solid fa-envelope topStripIcon"></i>;
const phone = <i className="fa-solid fa-phone topStripIcon"></i>;

const TopHeadStrip = () => {
  return (
    <React.Fragment>
      <Box className="topHeaderWrapper">
        <Box sx={{ margin: { xs: "0 auto", md: "0" } }}>
          <Typography variant="p" className="mailBox">
            {mail} medicalkey3@gmail.com {phone} +91-9341819717
          </Typography>
        </Box>
        <Box gap={2} sx={{ display: { md: "block", xs: "none" } }}>
          <Link to="">
            <i className="fa-brands fa-facebook topIcon"></i>
          </Link>
          <Link to="">
            <i className="fa-brands fa-instagram topIcon"></i>
          </Link>
          <Link to="https://youtube.com/@medicalkey3">
            <i className="fa-brands fa-youtube topIcon"></i>
          </Link>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default TopHeadStrip;
