import React from "react";
import { Box } from "@mui/material";
import "./floatingIconStyle.scss";

export const FloatingIcon = () => {
  const phoneNumber = "9341819717";
  const message = "Hello.";
  const email = " medicalkey3@gmail.com";
  const subject = "Inquiry";

  const phoneConnectHandler = () => {
    const phoneUrl = `tel:${phoneNumber}`;
    window.location.href = phoneUrl;
  };

  const emailConnectHandler = () => {
    const emailUrl = `mailto:${email}?subject=${encodeURIComponent(subject)}`;
    window.location.href = emailUrl;
  };

  const watsappConnectHandler = () => {
    const encodedMessage = encodeURIComponent(message);
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
    window.open(whatsappUrl, "_blank");
  };

  return (
    <React.Fragment>
      <Box
        className="floatingIconWrapper"
        data-aos="fade-left"
        data-aos-anchor="#example-anchor"
        data-aos-offset="500"
        data-aos-duration="500"
      >
        <Box className="phoneIcon" onClick={phoneConnectHandler}>
          <i className="fa-solid fa-phone"></i>
        </Box>
        <Box className="emailIcon" onClick={emailConnectHandler}>
          <i className="fa-solid fa-envelope"></i>
        </Box>
        <Box className="watsappIcon" onClick={watsappConnectHandler}>
          <i className="fa-brands fa-whatsapp"></i>
        </Box>
      </Box>
    </React.Fragment>
  );
};
