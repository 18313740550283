import React, { useState } from "react";
import { Box, Card, CardContent, Button, Typography } from "@mui/material";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import "./loginStyle.scss";

const Schema = Yup.object({
  username: Yup.string().trim().required(),
  password: Yup.string().min(6).trim().required(),
});

const Login = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const submitHandler = (values, { setSubmitting, resetForm }) => {
    console.log(values);
    const username = "alkama";
    const password = "123456";
    if (values.username === username && values.password === password) {
      console.log("loggedIn");
      setIsLoggedIn(true);
      localStorage.setItem('loggedin', isLoggedIn)
    } else {
      console.log("invalid!");
    }
    resetForm();
  };

  return (
    <React.Fragment>
      <Box className="loginWrapper">
        <Box className="formCardWrapper">
          <Card>
            <CardContent>
              <Formik
                initialValues={{ username: "", password: "" }}
                validationSchema={Schema}
                onSubmit={(values, action) => {
                  submitHandler(values, action);
                }}
              >
                {({ isSubmitting, errors, touched }) => (
                  <Form>
                    <Box className="mb-3">
                      <Field
                        name="username"
                        className="form-control"
                        placeholder="Userame"
                      />
                      {errors.username && touched.username ? (
                        <Typography className="error-message">
                          <i className="fa fa-circle-exclamation"></i> Username
                          Required
                        </Typography>
                      ) : null}
                    </Box>
                    <Box className="mb-3">
                      <Field
                        type="password"
                        name="password"
                        className="form-control"
                        placeholder="Password"
                      />
                      {errors.password && touched.password ? (
                        <Typography className="error-message">
                          <i className="fa fa-circle-exclamation"></i> Password
                          Required
                        </Typography>
                      ) : null}
                    </Box>
                    <Button type="submit">Sign In</Button>
                  </Form>
                )}
              </Formik>
            </CardContent>
          </Card>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default Login;
