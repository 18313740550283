import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import Img from "../../assets/images/plus.png"

const NumCounter = ({ countNum, className }) => {
  const maxCount = countNum;
  const [counter, setCounter] = useState(0);

  const counterMethod = () => {
    const interval = setInterval(() => {
      setCounter((counter) => (counter < maxCount ? counter + 1 : counter));
    }, 100);
    return () => {
      clearInterval(interval);
    };
  };

  useEffect(() => {
    counterMethod();
  }, []);

  return <Box className={className}>{counter}<img src={Img} alt="plus icon" width="30px" /></Box>;
};

export default NumCounter;
