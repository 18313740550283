import React, { useState, useEffect, Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.scss";
import "aos/dist/aos.css";
import AOS from "aos";
import Login from "./admin/login";
import Home from "./pages/home";
// import { DotLoader } from "react-spinners";
import { Box } from "@mui/material";
import { Helmet } from "react-helmet";
import LazyLoader from "./components/lazyLoader";

const LazyHomeHealthcare = lazy(() => import("./pages/homeHealthcare"));
const LazyAbout = lazy(() => import("./pages/about"));
const LazyServices = lazy(() => import("./pages/services"));
const LazyContact = lazy(() => import("./pages/contact"));
const LazyGallery = lazy(() => import("./pages/gallery"));
const LazyDepartmentList = lazy(() => import("./pages/departmentList"));
const LazySpecialitiesList = lazy(() => import("./pages/specialitiesList"));

function App() {
  const [loading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      <LazyLoader sx={{ display: "none" }} />;
      setIsLoading(false);
    }, 3000);
  }, []);

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <React.Fragment>
      {loading ? (
        <Box className="loader">
          <LazyLoader loadingText="Loading..." />
        </Box>
      ) : (
        <Router>
          <Helmet>
            <title>Medical Key | A key of your Health</title>
            <meta
              name="description"
              content="A Complete Healthcare Solution. Medical Key is a plateform that provide all medical & Health services. Providing HOME HEALTHCARE SERVICE in Patna. One stop solution for all your needs Sach as:-Best Doctor consultation, Best Hospital Suggestion, All kinds of Home service, Complete Hospitality Solution, Career Counseling, Placement Solution, Medical Equipment MRI, CT-SCAN, Blood Test, Ultrasound etc Ambulance, Medicine & Surgical items etc"
            ></meta>
            <meta
              name="keywords"
              content="Home Nursing care near me, Best Doctor in patna, Best Hospital in patna, Best orthopaedic Surgeon in patna, Placement agency, Medical Key patna, Urgent care near me, Hospital near me, Best Doctor near me, Healthcare Consultancy service"
            ></meta>
          </Helmet>
          <Routes>
            <Route path="/admin" element={<Login />} />
            <Route path="/" element={<Home />} />
            <Route
              path="/about"
              element={
                <Suspense fallback={<LazyLoader />}>
                  <LazyAbout />
                </Suspense>
              }
            />
            <Route
              path="/services"
              element={
                <Suspense fallback={<LazyLoader />}>
                  <LazyServices />
                </Suspense>
              }
            />
            <Route
              path="/contact"
              element={
                <Suspense fallback={<LazyLoader />}>
                  <LazyContact />
                </Suspense>
              }
            />
            <Route
              path="/gallery"
              element={
                <Suspense fallback={<LazyLoader />}>
                  <LazyGallery />
                </Suspense>
              }
            />
            <Route
              path="homehealthcare"
              element={
                <Suspense fallback={<LazyLoader />}>
                  <LazyHomeHealthcare />
                </Suspense>
              }
            />
            <Route
              path="/departmentList"
              element={
                <Suspense fallback={<LazyLoader />}>
                  <LazyDepartmentList />
                </Suspense>
              }
            />
            <Route
              path="/specialitiesList"
              element={
                <Suspense fallback={<LazyLoader />}>
                  <LazySpecialitiesList />
                </Suspense>
              }
            />
          </Routes>
        </Router>
      )}
    </React.Fragment>
  );
}

export default App;
