import React from "react";
import "./counterStyle.scss";
import { Box, Container, Grid, Typography } from "@mui/material";
import NumCounter from "./numCounter";
import Img from "../../assets/images/medical-team.png";
import Img2 from "../../assets/images/hospital.png";

const counterItems = [
  {
    icon: Img,
    value: <NumCounter countNum={12} className="counterDigit" />,
    title: "Doctors",
  },
  {
    icon: Img2,
    value: <NumCounter countNum={24} className="counterDigit" />,
    title: "Hospitals",
  },
  {
    icon: Img,
    value: <NumCounter countNum={36} className="counterDigit" />,
    title: "Doctor",
  },
  {
    icon: Img2,
    value: <NumCounter countNum={120} className="counterDigit" />,
    title: "Doctor",
  },
];

const CounterLayer = () => {
  return (
    <React.Fragment>
      <Box className="counterWrapper">
        <Container>
          <Grid container sx={{ textAlign: "center" }}>
            {counterItems.map((item, index) => (
              <Grid item md={3} xs={12} key={index}>
                {/* <Box><i className={`${item.icon} icon`}></i></Box> */}
                <img src={item.icon} width="80px" />
                <Typography variant="h2">{item.value}</Typography>
                <Typography variant="h4" className="title">
                  {item.title}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </React.Fragment>
  );
};

export default CounterLayer;
