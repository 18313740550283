import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import "./quotesCarouselStyle.scss";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const Quotes = [
  {
    label: "Right There With You!",
  },
  {
    label: "Our Expertise at Your Service!",
  },
  {
    label: "Your Health is Our Focus!",
  },
];

const QuotesCarousel = () => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  //   const maxSteps = images.length;

  const handleStepChange = (step) => {
    setActiveStep(step);
  };
  return (
    <React.Fragment>
      <Box className="quotesCarouselWrapper">
        <AutoPlaySwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
        >
          {Quotes.map((step, index) => (
            <Box key={step.label} data-aos="zoom-in-up">
              {Math.abs(activeStep - index) <= 2 ? (
                <Box>
                  <Typography className="quotesCarouselText">
                    {step.label}
                  </Typography>
                </Box>
              ) : null}
            </Box>
          ))}
        </AutoPlaySwipeableViews>
        <Typography><em>Medical Key | A key of your Health</em></Typography>
      </Box>
    </React.Fragment>
  );
};

export default QuotesCarousel;
