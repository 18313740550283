import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  Typography,
} from "@mui/material";
import React from "react";
import "./homeStyle.scss";
import CheckIcon from "../../assets/images/checklist.png";
import Img from "../../assets/images/info.jpg"

const Info = () => {
  return (
    <React.Fragment>
      <Box>
        <Grid container>
          <Grid item md={7} xs={12}>
            <Box className="leftImageWrapper">
              <img src={Img} alt="Info Banner" width="100%" />
            </Box>
          </Grid>
          <Grid item md={5} xs={12}>
            <Box className="rightContentWrapper">
              <List
                sx={{
                  width: "100%",
                  maxWidth: 400,
                  bgcolor: "background.paper",
                }}
              >
                {data.map((item, index) => (
                  <ListItem key={index}>
                    <ListItemAvatar>
                      <img src={CheckIcon} alt="check Icon" width="60px" />
                    </ListItemAvatar>
                    <Box>
                      <Typography variant="h4" className="title">{item.title}</Typography>
                      <Typography variant="span">{item.subTitle}</Typography>
                    </Box>
                    {/* <ListItemText
                      primary={item.title}
                      secondary={item.subTitle}
                    /> */}
                  </ListItem>
                ))}
              </List>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
};

export default Info;

const data = [
  {
    title: "Home Testing",
    subTitle: "All kinds of blood/urine/Covid Test/ECG in your door step.",
  },
  {
    title: "Medicine Delivery",
    subTitle: "All Medical Stationery Delivery at low cost in your door step. ",
  },
  {
    title: "Quickcare",
    subTitle:
      "Regular Checkup/Doctor Consultation (monthly Subscription) for Sr. Citizens and Differently Abled",
  },
  {
    title: "R.M.O Visit",
    subTitle: "Recident Medical Officer Home visit in Emergency 24x7",
  },
  {
    title: "Ambulance Service",
    subTitle: "Pickup/Drop Inter city | Inter state | Air Ambulance service.",
  },
];
